<template lang="pug">
  .qr
    qrcode-vue(:value="qrValue", :size="150", level="H")
</template>

<script>
import QrcodeVue from 'qrcode.vue';

export default {
  props: {
    apiKey: { type: Object, required: true }
  },
  components: { QrcodeVue },
  computed: {
    qrValue() {
      return JSON.stringify({
        apiKey: this.apiKey.api_key,
        secretKey: this.apiKey.api_secret,
        name: this.apiKey.name
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/variables';

</style>
