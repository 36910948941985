<template lang="pug">
  .wrapper(v-if="apiKeys")
    .zero(v-if="!apiKeys.length && !creatingKey")
      h1 {{ $t('settingsAPI.createTitle') }}
      v-layout(align-center)
        v-btn(@click="handleAddClick") + {{ $t('settingsAPI.addBtn') }}
        a(href="https://app.swaggerhub.com/apis/kickecosystem/KickEX-API-Beta", target="_blank") {{ $t('settingsAPI.apiDocs') }}
      .errors(v-if="twoFaError") {{ twoFaError }}

    v-layout(v-else)
      .subtitle {{ $t('settingsAPI.apiKeys') }}
      .keys_block(:class="{ creating: creatingKey }")
        v-layout.actions(justify-space-between)
          span(v-if="!creatingKey", @click="handleAddClick") + {{ $t('settingsAPI.addBtn') }}
          div
            a(href="https://app.swaggerhub.com/apis/kickecosystem/KickEX-API-Beta", target="_blank") {{ $t('settingsAPI.apiDocs') }}
            span.delete(v-if="apiKeys && apiKeys.length", @click="deleteAllApiKeys") {{ $t('settingsAPI.deleteAll') }}
        .errors(v-if="twoFaError") {{ twoFaError }}
        ApiKeyForm(
          v-if="creatingKey || editing",
          :platformId="3",
          :editing="editing",
          :editingKey="editingKey",
          @showDisableDialog="disable2FADialog = true",
          @cancelForm="cancelForm",
          @apiKeyCreated="handleApiKeyCreated",
          @apiKeyUpdated="handleApiKeyUpdated")
        .list(v-else)
          .list_item(v-for="(key, idx) in apiKeys", :key="idx", :class="{ deleted: key.is_deleted, blocked: key.is_blocked }")
            v-layout(wrap)
              ApiQR(v-if="key.newlyCreated", :apiKey="key")
              v-layout(justify-space-between)
                v-layout(column, align-start)
                  .field.name {{ $t('settingsAPI.apiKeyName') }}
                  div {{ key.name }}
                .actions
                  span(@click="handleEditClick(key)") {{ $t('actions.edit') }}
                  span(@click="handleDeleteClick(key)") {{ $t('actions.delete') }}
            ApiKeyAndSecretBlock(:apiKey="key.api_key", :apiSecret="key.api_secret", :showSecret="key.newlyCreated")
            .details
              div {{ $t('settingsAPI.permissions') }}: {{ getPermissionsStr(key.rights) }}
              div {{ $t('settingsAPI.ipLimit') }}: {{ key.ip.length ? $t('settingsAPI.ipWhiteList') + ' ' + key.ip.join(', ') : $t('settingsAPI.noLimit') }}
          v-pagination(
            v-if="totalPages > 1",
            v-model="currentPage",
            :length="totalPages",
            :total-visible="5",
            @input="getAPIKeys($event)")
    v-dialog(v-model="disable2FADialog", fullscreen)
      v-card
        .close(@click="disable2FADialog = false")
          SvgPlus
        v-layout.two_fa(column, align-start, justify-center)
          h1 {{ $t('2FA.APIpopup.title') }}
          p {{ $t('2FA.APIpopup.desc') }}
          router-link.v-btn.white(:to="{ name: 'settings-security' }", tag="button") {{ $t('2FA.APIpopup.btn') }}

    v-dialog(v-model="deletionModal", max-width="416", persistent)
      v-card.delete_card
        .close(@click="deletionModal = false")
          SvgPlus
        p {{ $t('settingsAPI.deleteApiKey', { word: deleteConfirmWord }) }}
        v-text-field(
          v-model.trim="deleteConfirm",
          @input="onDeleteConfirmInput"
          hide-details)
</template>

<script>
import { mapState } from 'vuex';
import QrcodeVue from 'qrcode.vue';
import ApiKeyForm from '@/components/ApiKeyForm';
import ApiKeyAndSecretBlock from '@/components/ApiKeyAndSecretBlock';
import ApiQR from '@/components/ApiKeyQR';
import SvgPlus from '@/components/svg/SvgPlus';

export default {
  components: {
    QrcodeVue,
    ApiKeyForm,
    ApiKeyAndSecretBlock,
    ApiQR,
    SvgPlus
  },
  data() {
    return {
      platformsID: [3],
      sortingOption: '-id',
      pageSize: 10,
      currentPage: 1,
      totalPages: 1,
      apiKeys: null,
      twoFaError: '',
      creatingKey: false,
      deletionModal: false,
      deletingKey: null,
      deleting: false,
      editingKey: null,
      editing: false,
      deleteConfirmWord: 'DELETE',
      deleteConfirm: '',
      disable2FADialog: false
    };
  },
  computed: {
    ...mapState('user', { isTwoFaEnabled: 'is_2fa_enabled', twoFAType: '2fa_type' })
  },
  methods: {
    getAPIKeys(page = 1) {
      // test-2@kickecosystem.com
      // 123456789aA
      const params = {
        'page[number]': page,
        'page[size]': this.pageSize,
        sort: this.sortingOption,
        'filter[platform]': this.platformsID.join()
      };

      this.$store.dispatch('user/getAPIKeys', params)
        .then(({ data, meta }) => {
          this.apiKeys = data;
          this.page = meta.pagination.current_page;
          this.totalPages = meta.pagination.total_pages;
        })
        .catch(err => console.error(err));
    },
    handleAddClick() {
      this.editing = false;
      this.editingKey = null;

      if (this.isTwoFaEnabled) {
        if (this.twoFAType === 2) {
          this.disable2FADialog = true;
        } else {
          this.creatingKey = true;
        }
      } else {
        this.twoFaError = this.$t('settingsAPI.twoFaRequiredError');
      }
    },
    handleApiKeyCreated(apiKey) {
      this.creatingKey = false;
      apiKey.newlyCreated = true;

      this.apiKeys.unshift(apiKey);
    },
    handleApiKeyUpdated() {
      this.editing = false;
      this.editingKey = null;
      this.getAPIKeys();
    },
    getPermissionsStr(arr) {
      return arr.map(i => this.$t(`settingsAPI.rights.${i}`)).join(', ');
    },
    handleDeleteClick(key) {
      this.deletionModal = true;
      this.deletingKey = key;
    },
    deleteApiKey() {
      if (!this.deletingKey || this.deleting || this.deletingKey.is_deleted) {
        this.deletionModal = false;
        return;
      }

      this.deleting = true;

      this.$store.dispatch('user/deleteApiKey', this.deletingKey.id)
        .then(() => {
          this.deletionModal = false;
          this.deletingKey = null;
          this.getAPIKeys(1);
        })
        .finally(() => {
          this.deleting = false;
          this.deleteConfirm = '';
        });
    },
    handleEditClick(key) {
      if (this.editing) return;

      this.editing = true;
      this.editingKey = key;
    },
    cancelForm() {
      this.creatingKey = false;
      this.editing = false;
      this.editingKey = null;
    },
    onDeleteConfirmInput(val) {
      if (val.toUpperCase() !== this.deleteConfirmWord) return;

      if (this.deletingKey) {
        this.deleteApiKey();
      } else {
        if (this.deleting) return;

        this.deleting = true;

        this.$store.dispatch('user/deleteAllApiKeys')
          .then(() => {
            this.deletionModal = false;
            this.getAPIKeys(1);
          })
          .finally(() => {
            this.deleting = false;
            this.deleteConfirm = '';
          });
      }
    },
    deleteAllApiKeys() {
      this.deletionModal = true;
      this.deletingKey = null;
    }
  },
  beforeMount() {
    this.getAPIKeys(this.currentPage);
  }
};
</script>

<style lang="scss" scoped>
  @import '../assets/scss/variables';

  .wrapper {
    padding-top: 98px;
    text-align: left;

    .zero {
      h1 {
        font-size: 35px;
        margin-bottom: 34px;
      }

      a { font-size: 18px; }

      .v-btn {
        padding: 19px 60px !important;
        margin-right: 75px;
      }
    }

    .subtitle {
      font-size: 16px;
      font-weight: 600;
      width: 200px;
    }

    .errors {
      color: $error-color;
      margin-top: 10px;
      font-size: 14px;
    }

    a {
      text-decoration: none;
      color: $blue;
      font-weight: 500;
    }

    .keys_block {
      width: calc(100% - 200px);

      .actions {
        max-width: 553px;
        font-size: 14px;
        color: $blue;
        cursor: pointer;
        font-weight: 500;

        & > div > * {
          display: inline-block;

          &:not(:last-child) {  margin-right: 18px; }
        }

        & > span {
          font-size: 16px;
          font-weight: 600;
        }
      }

      .list {
        max-width: 550px;
        margin-top: 70px;

        &_item {
          font-size: 18px;
          font-weight: 500;
          margin-top: 30px;
          padding-bottom: 30px;
          border-bottom: 1px solid $grey-light;

          &.deleted {
            opacity: .5;

            .actions { display: none; }
          }

          .qr { margin-right: 30px; }

          .block { margin-top: 30px; }

          .field {
            font-size: 16px;
            color: $grey-light13;
            white-space: nowrap;

            &:not(.name) { flex-grow: 1; }
          }

          .details {
            font-size: 14px;
            color: $grey-light13;
            font-weight: 600;
            margin-top: 26px;
            max-width: 550px;

            & > div { margin-bottom: 9px; }
          }

          .actions {
            text-align: right;

            & > span {
              font-size: 14px;
              margin-left: 25px;
            }
          }
        }
      }
    }

    @media screen and (max-width: 760px) {
      padding-top: 60px;

      & > .layout {
        flex-wrap: wrap;
        position: relative;

        .subtitle,
        .keys_block,
        .keys_block > .actions {
          width: 100%;
          max-width: 100%;
        }

        .keys_block > .actions > div > * {
          margin-right: 0 !important;
        }

        .subtitle { margin-bottom: 25px; }

        .delete {
          position: absolute;
          top: 0;
          right: 0;
        }
      }

      .list { max-width: 100% !important; }
    }

    @media screen and (max-width: 600px) {
      .zero  {
        h1 { font-size: 30px; }

        .layout {
          flex-wrap: wrap;

          .v-btn {
            display: block;
            width: 100%;
            margin-right: 0;
            margin-bottom: 35px;
          }

          a {
            display: block;
            width: 100%;
            text-align: center;
          }
        }
      }

      .list_item,
      .list_item .field,
      .list_item .actions {
        font-size: 14px !important;
      }

      .secret { padding-top: 0 !important; }
    }
  }

  .delete_card {
    padding: 50px 35px 35px;
    font-size: 18px;
    font-weight: 600;
    position: relative;

    ::v-deep .v-input__slot {
      padding-top: 4px !important;
    }
  }

  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 24px;
    min-width: 24px;
    height: 24px;
    outline: none;
    transform: rotate(45deg);
    margin: auto 0 auto auto;
    opacity: 0.54;
    cursor: pointer;
  }

  .v-dialog .two_fa {
    min-height: 100vh;
    max-width: 550px;
    margin: 0 auto;
    text-align: left;
    font-size: 20px;

    .v-btn {
      margin-top: 35px;
      box-shadow: 0px 0px 6px $light-theme-05, 0px 8px 22px $light-theme-08;
    }
  }
</style>
