<template lang="pug">
  .key_secret
    .block
      .field {{ $t('settingsAPI.key') }}:
      v-layout(justify-space-between)
        .key {{ apiKey }}
        .actions
          span(@click="copyKeyToClipboard") {{ $t('actions.copy') }}
          input(type="text", :value="apiKey", :id="apiKey")
    .block
      .field {{ $t('settingsAPI.secret') }}:
      v-layout(align-center)
        .secret(:class="{ hidden: !showSecret }") {{ showSecret ? apiSecret : '*******************************************************************************' }}
        //- .hint
        //-   SvgListError
    v-snackbar(v-model="snackbar", top, :timeout="800") {{ $t('messages.inform.copiedToClipboard') }}
</template>

<script>
import SvgListError from '@/components/svg/SvgListError';

export default {
  components: { SvgListError },
  props: {
    apiKey: { type: String, required: true },
    apiSecret: { type: String, default: '' },
    showSecret: { type: Boolean, default: false }
  },
  data() {
    return {
      snackbar: false
    };
  },
  methods: {
    copyKeyToClipboard() {
      const hashInput = document.getElementById(this.apiKey);

      hashInput.select();
      document.execCommand('copy');

      this.snackbar = true;
    }
  }
};
</script>

<style lang="scss" scoped>
  @import '../assets/scss/variables';

  .block {
    margin-top: 30px;

    .key { word-break: break-all; }
  }

  .field {
    font-size: 16px;
    color: $grey-light13;
    flex-grow: 1;
    white-space: nowrap;
  }

  .secret {
    word-break: break-all;

    &.hidden {
      text-overflow: clip;
      overflow: hidden;
      white-space: nowrap;
      padding-top: 7px;
    }

    @media screen and (max-width: 553px) {
      width: calc(100vw - 40px);
    }
  }

  .actions {
    text-align: right;

    & > span {
      font-size: 14px;
      margin-left: 25px;
      color: $blue;
      cursor: pointer;
      font-weight: 500;
    }
  }

  .hint {
    width: 16px;
    margin-left: 8px;
    cursor: pointer;

    svg {
      width: 16px !important;
      min-width: 16px !important;
      height: 16px !important;

      ::v-deep path { fill: $grey-light12; };
    }
  }

  input {
    height: 0;
    width: 1px;
  }
</style>
