<template lang="pug">
  v-layout.form(v-if="!showTwoFaForm")
    v-form(ref="form", v-model="valid", @submit.prevent="")
      v-text-field(
        v-if="!editing",
        v-model.trim="keyName",
        :label="$t('settingsAPI.apiKeyName')",
        :rules="[rules.required, rules.name]")
      v-layout(v-else, wrap)
        ApiQR(v-if="editingKey.newlyCreated", :apiKey="editingKey")
        v-layout(column, align-start)
          .subtitle {{ $t('settingsAPI.apiKeyName') }}
          .key {{ keyName }}
      v-text-field(
        v-if="!editing",
        type="password",
        v-model="password",
        :label="$t('settingsAPI.password')",
        :rules="[rules.required, rules.password]")
      v-text-field(
        v-if="!editing",
        type="password",
        v-model="passwordAgain",
        :error-messages="passwordMatchError()",
        :label="$t('settingsAPI.repeatPassword')")
      v-layout
        ApiKeyAndSecretBlock(v-if="editing", :apiKey="editingKey.api_key", :apiSecret="editingKey.api_secret", :showSecret="editingKey.newlyCreated")
      .subtitle {{ $t('settingsAPI.permissions') }}
      v-layout.rights(align-center)
        v-checkbox(v-model="rights", :label="$t('settingsAPI.rights.reader')", value="reader", disabled, hide-details)
        v-checkbox(v-model="rights", :label="$t('settingsAPI.rights.trader')", value="trader", hide-details)
        //- v-checkbox(v-model="rights", :label="$t('settingsAPI.rights.withdraw')", value="withdraw", disabled, hide-details)
        //- .hint
        //-   SvgListError
      .subtitle {{ $t('settingsAPI.ipLimit') }}
      v-radio-group(v-model="ipLimit", row)
        v-radio(:label="$t('settingsAPI.noLimit')", :value="false", color="#FF782B", disabled)
        v-radio(:label="$t('settingsAPI.ipWhiteList')", :value="true", color="#FF782B")
      v-text-field.ip_input(
        v-if="ipLimit",
        v-model="newIp",
        :label="$t('settingsAPI.yourIp')",
        :rules="[rules.ipAddress, rules.atLeastOneAddress]",
        @keydown.enter="handleIpAdd")
        template(v-slot:append="")
          span(@click="handleIpAdd") {{ $t('actions.add') }}
      .ip_list(v-if="ipLimit")
        transition-group(name="list")
          .ip_list_item(v-for="(ip, idx) in ipWhiteList", :key="'ip_' + idx") {{ ip }} #[span(@click="removeFromWhiteList(idx)") {{ $t('actions.delete') }}]
      .btns
        v-btn.white.shadowed(@click="handleSubmit", :disabled="!valid || loading") {{ editing ? $t('actions.save') : $t('actions.create') }}
        v-btn.white(@click="onCancelClick") {{ $t('actions.cancel') }}
      .errors(v-if="validationErrors.length", v-for="error in validationErrors") #[strong {{ error.title }}:] {{ error.detail }}
    .description(v-if="!editing") {{ $t('hints.rememberApiPassword') }}
  .process_verification(v-else)
    h1.process__verification-title {{ $t('hints.enterVerificationCode') }}
    SecureCodeInputs.api__security_code(
      :errors="twoFaErrors",
      :twoFAType="user2fa_type",
      v-model="twoFaCode",
      :blurOnComplete="true")
</template>

<script>
import SecureCodeInputs from '@/components/SecureCodeInputs';
import REGEXP from '@/regexp';
import ApiKeyAndSecretBlock from '@/components/ApiKeyAndSecretBlock';
import ApiQR from '@/components/ApiKeyQR';

export default {
  components: { SecureCodeInputs, ApiKeyAndSecretBlock, ApiQR },
  props: {
    platformId: { type: Number, required: true },
    editing: { type: Boolean, default: false },
    editingKey: { type: Object, default: () => {} }
  },
  data() {
    return {
      valid: false,
      keyName: '',
      password: '',
      passwordAgain: '',
      rights: ['reader'],
      ipLimit: true,
      newIp: '',
      ipWhiteList: [],
      validationErrors: [],
      loading: false,
      showTwoFaForm: false,
      twoFaCode: '',
      twoFaErrors: [],
      formData: null
    };
  },
  computed: {
    user2fa_type() { return this.$store.state.user['2fa_type']; },
    rules() {
      return {
        required: v => this.editing || !!v.trim() || this.$t('validation.required'),
        ipAddress: v => !v.trim() || REGEXP.ipAddress.test(v.trim()) || this.$t('validation.ipValid'),
        atLeastOneAddress: v => !!v || !!this.ipWhiteList.length || this.$t('validation.required'),
        name: v => /^[_0-9a-z]{1,25}$/.test(v) || this.$t('validation.apiKeyName', { min: 1, max: 25 }),
        password: v => REGEXP.passPhrase.test(v) || this.$t('validation.passphrase', { min: 20, max: 160 })
      };
    },
    areRightsUnchanged() {
      return this.editingKey.rights.length === this.formData.rights.length
        && this.formData.rights.every(i => this.editingKey.rights.includes(i));
    },
    areIPsUnchanged() {
      return this.editingKey.ip.length === this.formData.ip.length
        && this.formData.ip.every(i => this.editingKey.ip.includes(i));
    },
    isEditFormUnchanged() {
      return this.areRightsUnchanged && this.areIPsUnchanged;
    },
    is2FANeeded() {
      return !this.isEditFormUnchanged
      && (this.formData.ip.some(i => !this.editingKey.ip.includes(i))
      || this.formData.rights.some(i => !this.editingKey.rights.includes(i)));
    }
  },
  watch: {
    editing(val) {
      if (!val) {
        this.keyName = '';
        this.newIp = '';
        this.ipWhiteList = [];
        this.rights = ['reader'];
        this.$refs.form.resetValidation();
      }
    },
    twoFaCode(val) {
      if (val.length !== 6) return;

      this.formData['2fa_code'] = this.twoFaCode;

      if (this.editing) {
        this.updateApiKey();
      } else {
        this.createApiKey();
      }
    }
  },
  methods: {
    passwordMatchError() {
      if (this.password.length < 20 || this.editing) return '';

      if (this.password.length >= 20 && !this.passwordAgain) return this.$t('validation.required');

      return (this.password === this.passwordAgain) ? '' : this.$t('validation.passwordsDoNotMatch');
    },
    handleIpAdd() {
      if (REGEXP.ipAddress.test(this.newIp.trim())) {
        this.ipWhiteList.push(this.newIp.trim());
        this.newIp = '';
      }
    },
    removeFromWhiteList(idx) {
      this.ipWhiteList.splice(idx, 1);
      this.$refs.form.validate();
    },
    onCancelClick() {
      if (!this.editing) {
        this.$refs.form.reset();
      }

      this.$emit('cancelForm');
    },
    validateApiKeyCreation() {
      this.$store.dispatch('user/validateApiKeyCreation', this.formData)
        .then(() => {
          this.showTwoFaForm = true;
        })
        .catch(errors => {
          this.validationErrors = errors;
          this.loading = false;
        });
    },
    validateApiKeyUpdate() {
      this.$store.dispatch('user/validateApiKeyUpdate', this.formData)
        .then(() => {
          if (this.is2FANeeded) {
            if (this.user2fa_type === 2) {
              this.$emit('showDisableDialog');
            } else {
              this.showTwoFaForm = true;
            }
          } else {
            this.updateApiKey();
          }
        })
        .catch(errors => {
          this.validationErrors = errors;
          this.loading = false;
        });
    },
    handleSubmit() {
      if (!this.$refs.form.validate()) return;

      this.formData = { rights: [...this.rights] };

      if (this.newIp) {
        this.ipWhiteList.push(this.newIp);
        this.newIp = '';
      }

      if (this.ipWhiteList.length) {
        this.formData.ip = [...this.ipWhiteList];
      }

      this.loading = true;

      if (!this.editing) {
        this.formData = {
          ...this.formData,
          platform_id: this.platformId,
          name: this.keyName,
          date_start: Date.now(),
          date_end: 8640000000000000,
          passphrase: this.password
        };

        this.validateApiKeyCreation();
      } else {
        this.formData.id = this.editingKey.id;

        if (this.isEditFormUnchanged) {
          this.loading = false;
          return;
        }

        this.validateApiKeyUpdate();
      }
    },
    createApiKey() {
      this.$store.dispatch('user/createApiKey', this.formData)
        .then(res => this.$emit('apiKeyCreated', res))
        .catch(errors => {
          this.twoFaErrors = errors;
        });
    },
    updateApiKey() {
      if (this.areRightsUnchanged) delete this.formData.rights;

      if (this.areIPsUnchanged) delete this.formData.ip;

      // TODO: remove the line below when the backend validation is fixed
      if (!this.is2FANeeded) this.formData['2fa_code'] = '111111';

      this.$store.dispatch('user/updateApiKey', this.formData)
        .then(() => {
          this.$emit('apiKeyUpdated');
        })
        .catch(errors => {
          if (this.is2FANeeded) {
            this.twoFaErrors = errors;
          } else {
            this.validationErrors = errors;
          }
        })
        .finally(() => { this.loading = false; });
    },
    copyKeyToClipboard() {
      const hashInput = document.getElementById(this.editingKey.id);
      hashInput.select();
      document.execCommand('copy');

      this.$emit('keyCopied');
    }
  },
  mounted() {
    if (this.editing) {
      this.keyName = this.editingKey.name;
      this.rights = [...new Set([...this.rights, ...this.editingKey.rights])];
      this.ipWhiteList = [...this.editingKey.ip];
      this.$refs.form.validate();
    }
  },
  beforeDestroy() {
    this.formData = null;
  }
};
</script>

<style lang="scss" scoped>
  @import '../assets/scss/variables';

  .form {
    width: 100%;
    margin-top: 69px;

    .v-form {
      max-width: 553px;
      flex-grow: 1;
    }

    .description {
      flex-grow: 1;
      max-width: 230px;
      font-size: 12px;
      color: $grey-text;
      padding: 95px 0 0 26px;
    }

    .subtitle {
      color: $grey-text;
      font-weight: 500;
      margin-bottom: 12px;
    }

    .rights {
      margin-bottom: 28px;

      .hint {
        margin-left: 8px;
        cursor: pointer;

        svg {
          width: 16px !important;
          min-width: 16px !important;
          height: 16px !important;
          margin-top: 7px;

          ::v-deep path { fill: $grey-light12; };
        }
      }

      ::v-deep .v-input--checkbox {
        .v-input__slot label {
          color: $sub-black;
          font-weight: 500;
        }

        &:not(:first-child) { margin-left: 30px; }
      }
    }

    .ip_input {
      margin-bottom: 0;

      ::v-deep .v-input__append-inner {
        margin-top: 0;
        color: $blue;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;

        span {
          display: inline-block;
          padding: 3px 10px;
        }
      }

      &.error--text ::v-deep .v-input__append-inner {
        color: $grey-light12;
        cursor: default;
      }
    }

    .ip_list {
      font-size: 18px;
      font-weight: 500;

      &_item span {
        color: $blue;
        font-size: 14px;
        cursor: pointer;
        margin-left: 5px;
      }
    }

    .btns {
      margin-top: 40px;

      .v-btn {
        padding-top: 17px !important;
        padding-bottom: 17px !important;
        margin-bottom: 30px;

        &:disabled { color: $grey-light12 !important; }

        &:first-child {
          margin-right: 30px;
          padding-left: 70px !important;
          padding-right: 70px !important;
        }
      }
    }

    .key_secret { margin-bottom: 20px; }

    .qr { margin-right: 20px; }

    @media screen and (max-width: 760px) {
      flex-wrap: wrap;
      margin-top: 27px;

      .description {
        order: 1;
        max-width: 100%;
        padding: 0;
        margin-bottom: 30px;
      }

      .v-form { order: 2; }

      .rights {
        flex-direction: column !important;
        align-items: flex-start !important;

        .v-input--checkbox:not(:first-child) {
          margin-left: 0;
          margin-top: 15px;
        }
      }

      ::v-deep .v-input--radio-group__input {
        flex-direction: column !important;

        .v-radio:not(:last-child) { margin-bottom: 15px; }
      }
    }

    @media screen and (max-width: 600px) {
      .btns .v-btn { width: 100%; }

      .ip_list_item {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  .process_verification { margin-top: 50px; }

  .list-enter-active,
  .list-leave-active {
    transition: all .5s $easing;
  }

  .list-enter,
  .list-leave-to {
    opacity: 0;
    transform: translateY(30px);
  }

  .errors {
    color: $error-color;
    margin-top: 10px;
    font-size: 14px;
  }
</style>
