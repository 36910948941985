import { render, staticRenderFns } from "./SettingsAPI.vue?vue&type=template&id=7012f4ec&scoped=true&lang=pug&"
import script from "./SettingsAPI.vue?vue&type=script&lang=js&"
export * from "./SettingsAPI.vue?vue&type=script&lang=js&"
import style0 from "./SettingsAPI.vue?vue&type=style&index=0&id=7012f4ec&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7012f4ec",
  null
  
)

export default component.exports